<script setup lang="ts">
import type { IColumn, IComparedData } from '@audit'
import type { IPagination } from '@shared/composables/usePagination'
import { useScheduleComparisonColumns } from '@audit/components/composables/useScheduleComparisonColumns'
import useColumnLoading from './composables/useColumnLoading'
import type { DataTableColumn } from '~/layers/ui/components/DataTable/types'

interface Props {
  data: IComparedData[]
  loading?: boolean
  pagination?: IPagination
  total?: number
  selectedRows?: IComparedData[]
  columns: IColumn[]
}
const props = withDefaults(defineProps<Props>(), {
  loading: false,
  pagination: () => ({ page: 1, pageSize: 25 }),
  total: 0,
  selectedRows: () => [],
})

const colsSkeleton = useColumnLoading()
const formattedColumns = useScheduleComparisonColumns(
  computed(() => (props.columns.length ? props.columns : colsSkeleton)),
)
</script>

<template>
  <div
    class="my-4 flex size-full overflow-x-auto rounded-lg border border-gray-300 p-0"
  >
    <DataTable
      :total-records="total"
      :columns="formattedColumns"
      :data="data"
      :loading="loading"
      class="h-[460px] w-full min-w-max rounded-lg"
    >
      <template #empty>
        <div class="mt-8 flex flex-col items-center justify-center pb-12 pt-8">
          <h1>No reconciliation found</h1>
          <Icon name="no-lease" class="my-8 text-8xl text-gray-200" />
        </div>
      </template>
      <template #item="{ id, item: obj }: DataTableColumn<IComparedData>">
        <LazyComparisonScheduleExceptionTableField
          :value="obj[id]"
          :format="obj[id].dataType"
          :field-name="id"
        />
      </template>
    </DataTable>
  </div>
</template>

<style scoped>
:deep(td) {
  padding: 0;
}
</style>
